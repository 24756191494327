<template>
<div class="wrap">
  <a-result status="404" :title="$route.query.title||404" sub-title="Sorry, the page you visited error.">
  </a-result>
  </div>
</template>
  <script>
export default {
  data() {
    return {
        
    };
  },
  mounted(){
    clearInterval();
  }
};
</script>
<style lang="scss" scoped>
.wrap{
  // width:100vh;
  height:100vh;
  background:#fff;
}
</style>